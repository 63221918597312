import { Grid, GridColumn as Column, GridNoRecords, GridCellProps, GridToolbar } from '@progress/kendo-react-grid';
import { IconButton, Snackbar, SnackbarCloseReason } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import EditNotificationForm from './EditNotificationForm';
import { fetchApi } from 'core/fetchApi';

export type NotificationResponse = {
  Notifications: CarrierContactQuoteOfferNotificationViewModel[];
  VehicleTypes: { ID: number, Name: string }[];
}

export type CarrierContactQuoteOfferNotificationViewModel = {
  CarrierContactQuoteOfferNotificationID: number;
  VehicleTypeID: number | null;
  FromState: string;
  ToState: string;
  Weekdays: string;
  StartTime: string;
  EndTime: string;
  MatchedPostedVehicle: boolean;
}

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: CarrierContactQuoteOfferNotificationViewModel) => void;
  deleteItem: (item: CarrierContactQuoteOfferNotificationViewModel) => void;
}

const EditCommandCell = (props: EditCommandCellProps) => {
  return (
    <td>
      <Button
        themeColor="primary"
        onClick={() => props.enterEdit(props.dataItem)}
      >
        Edit
      </Button>
      &nbsp;
      <Button
        themeColor="warning"
        onClick={() => props.deleteItem(props.dataItem)}
      >
        Delete
      </Button>
    </td>
  );
};

const Notifications = () => {
  const data = useLoaderData() as NotificationResponse;
  const revalidator = useRevalidator();
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState<CarrierContactQuoteOfferNotificationViewModel>();
  const [snackbar, setSnackbar] = useState('');
  const enterEdit = (item: CarrierContactQuoteOfferNotificationViewModel) => {
    setEditItem(item);
    setOpenForm(true);
  };

  const deleteItem = (item: CarrierContactQuoteOfferNotificationViewModel) => {
    fetchApi({ url: `LoadBoardNotification/${item.CarrierContactQuoteOfferNotificationID}`, method: 'DELETE' })
      .then(() => {
        setSnackbar('Notification rule deleted');
        revalidator.revalidate();
      })
      .catch(() => {
        setSnackbar('Error deleting notification rule');
      });
  }

  const handleSubmit = (item: CarrierContactQuoteOfferNotificationViewModel) => {
    fetchApi({ url: 'LoadBoardNotification', method: 'POST', payload: item })
      .then(() => {
        setOpenForm(false);
        setSnackbar('Notification rule saved');
        revalidator.revalidate();
      })
      .catch(() => {
        setSnackbar('Error saving notification rule');
      });
  };

  const handleCloseSnackbar = (_: Event | React.SyntheticEvent, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar('');
  };

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props} enterEdit={enterEdit} deleteItem={deleteItem} />
  );

  return <>
    <Grid data={data.Notifications} size='small'>
      <GridToolbar>
        <Button
          icon="plus"
          themeColor="primary"
          onClick={() => {
            setEditItem({
              CarrierContactQuoteOfferNotificationID: 0,
              VehicleTypeID: null,
              FromState: '',
              ToState: '',
              Weekdays: 'YYYYYYY',
              StartTime: '00:00:00',
              EndTime: '23:59:59',
              MatchedPostedVehicle: false,
            });
            setOpenForm(true);
          }}
        >
          Add Email Notification
        </Button>
      </GridToolbar>
      <GridNoRecords>
        No posted load email notification rules setup.
      </GridNoRecords>
      <Column field="VehicleTypeID" title="Vehicle Type" cell={(props) => {
        const vehicleType = data.VehicleTypes.find(x => x.ID === props.dataItem.VehicleTypeID);
        return <td>{vehicleType ? vehicleType.Name : 'Any'}</td>;
      }} />
      <Column field="FromState" title="From State" cell={(props) => {
        return <td>{props.dataItem.FromState || 'Any'}</td>;
      }} />
      <Column field="ToState" title="To State" cell={(props) => {
        return <td>{props.dataItem.ToState || 'Any'}</td>;
      }} />
      <Column field="Weekdays" title="Schedule" cell={(props) => {
        if (props.dataItem.Weekdays === 'YYYYYYY') return <td>Everyday</td>;
        if (props.dataItem.Weekdays === 'NYYYYYN') return <td>Weekdays</td>;
        if (props.dataItem.Weekdays === 'YNNNNNY') return <td>Weekends</td>;
        if (props.dataItem.Weekdays === 'NNNNNNN') return <td className='k-text-error'>Never</td>;
        return <td>{(props.dataItem.Weekdays as string).split('').map((x, i) => x === 'Y' ? daysOfWeek[i] : '').filter(x => x).join(', ')}</td>;
      }} />
      <Column field="StartTime" title="Schedule Start" cell={(props) => {
        return <td>{props.dataItem.StartTime} EST</td>;
      }} />
      <Column field="EndTime" title="Schedule End" cell={(props) => {
        return <td>{props.dataItem.EndTime} EST</td>;
      }} />
      <Column field="MatchedPostedVehicle" title="Only Matches" cell={(props) => {
        return <td>{props.dataItem.MatchedPostedVehicle ? 'Yes' : 'No'}</td>;
      }} />
      <Column cell={MyEditCommandCell} />
    </Grid>
    {openForm && editItem && <EditNotificationForm
      cancelEdit={() => setOpenForm(false)}
      onSubmit={handleSubmit}
      item={editItem}
      vehicleTypes={data.VehicleTypes}
    />}
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!snackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      message={snackbar}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={(e) => handleCloseSnackbar(e, 'timeout')}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  </>;
}

export default Notifications;