import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Copyright } from 'components/copyright';
import { useAuthDispatch, useAuthState } from 'contexts/auth/context';
import { Alert, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Link as MULink, TextField, Typography, useTheme, Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/images/bg-hero.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(1),
    maxWidth: 200,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const Login = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();
  const { search, signOut, verification } = useAuthDispatch();
  const { email, errorMessage, loading, refreshToken, waitingVerification } = useAuthState();

  const [codeInput, setCodeInput] = useState('');
  const [emailInput, setEmailInput] = useState(email);

  useEffect(() => {
    if (errorMessage === "NOCARRIERS") navigate('/signup');
  }, [errorMessage, navigate]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    search(emailInput.trim());
  }

  return <Grid container component="main" sx={styles.root}>
    <Grid
      item
      xs={false}
      sm={4}
      md={7}
      sx={styles.image}
    />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Backdrop style={styles.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <img style={styles.logo} src="/images/logo.png" alt="Load 1 | Transportation &amp; Logistics" />

        <Typography component="h1" variant="h5">
          Carrier Login
        </Typography>

        <Typography component="p" variant="body1">
          Bid on opportunities and view up to-date carrier performance metrics.
        </Typography>

        {errorMessage === "NOCARRIERS" && refreshToken && waitingVerification === false && <Alert severity="warning">
          You&apos;re not setup with Load One as a Carrier Contact.
        </Alert>}

        <Box component="form" onSubmit={handleSubmit} style={styles.form}>

          <TextField
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <Dialog open={waitingVerification} aria-labelledby="login-dialog-title">
            <DialogTitle id="login-dialog-title">Login</DialogTitle>
            <DialogContent>
              <DialogContentText>
                We sent you an email with a verification code. Please enter the code here.
              </DialogContentText>
              <TextField
                autoFocus
                value={codeInput}
                onChange={(e) => setCodeInput(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="auth-token"
                label="Code"
                type="number"
                id="number"
                inputMode="numeric"
                autoComplete="one-time-code"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={signOut} color="primary">
                Cancel
              </Button>
              <Button onClick={() => verification(codeInput)} color="primary">
                Login
              </Button>
            </DialogActions>
          </Dialog>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={styles.submit}
          >
            Email Login Code
          </Button>

          <Grid container>
            <Grid item>
              <MULink component={Link} to="/signup" variant="body2">
                Don&apos;t have an account? Sign Up
              </MULink>
            </Grid>
          </Grid>

          <Box mt={5}>
            <Copyright />
          </Box>

        </Box>
      </Box>
    </Grid>
  </Grid>;
}

export default Login;