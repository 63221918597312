import { parseJSON } from 'date-fns';
import { format } from 'date-fns-tz';
import { GridCellProps } from '@progress/kendo-react-grid';

const DateTimeCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;
  if (!props.dataItem[props.field]) return <td />;

  return <td>{format(parseJSON(props.dataItem[props.field]), 'LL/dd/yyyy HH:mm zzz')}</td>
}

export default DateTimeCell;
