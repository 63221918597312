import { useEffect, useState } from 'react';
import { parseJSON, intervalToDuration, differenceInMilliseconds } from 'date-fns';
import { GridCellProps } from '@progress/kendo-react-grid';
import './Countdown.css';

const CountdownCell = (props: GridCellProps) => {
  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!props.field || props.rowType !== 'data') return null;
  if (!props.dataItem[props.field]) return <td />;

  const msDuration = differenceInMilliseconds(parseJSON(props.dataItem[props.field]), new Date());
  if (msDuration <= 0) {
    return <td className="countdown-critical">Expired</td>
  }
  const duration = intervalToDuration({ start: 0, end: msDuration })

  if (duration.days && duration.days > 1) {
    const formattedDuration = `${duration.days}d ${duration.hours}h ${duration.minutes}m ${duration.seconds}s`;
    return <td>{formattedDuration}</td>;
  }

  const formattedDuration = `${duration.hours}h ${duration.minutes}m ${duration.seconds}s`;
  if (msDuration > 1000 * 60 * 10) {
    return <td>{formattedDuration}</td>;
  }

  if (msDuration > 1000 * 60 * 5) {
    return <td className="countdown-warning">{formattedDuration}</td>;
  }

  return <td className="countdown-critical">{formattedDuration}</td>;
}

export default CountdownCell;
