import { useContext, createContext } from 'react';
import { LoadBoardStateContextData, LoadBoardDispatchContextData } from './types';

export const LoadBoardStateContext = createContext<LoadBoardStateContextData | undefined>(
  undefined,
);
export const LoadBoardDispatchContext = createContext<
  LoadBoardDispatchContextData | undefined
>(undefined);

export const LoadBoardStateProvider = LoadBoardStateContext.Provider;
export const LoadBoardDispatchProvider = LoadBoardDispatchContext.Provider;

export const useLoadBoardState = (): LoadBoardStateContextData => {
  const context = useContext(LoadBoardStateContext);

  if (!context) {
    throw new Error("useLoadBoardState must be used within a LoadBoardProvider");
  }

  return context;
};

export const useLoadBoardDispatch = (): LoadBoardDispatchContextData => {
  const context = useContext(LoadBoardDispatchContext);

  if (!context) {
    throw new Error("useLoadBoardDispatch must be used within a LoadBoardProvider");
  }

  return context;
};
