import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox, MaskedTextBox, TextBox } from "@progress/kendo-react-inputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { CarrierContactQuoteOfferNotificationViewModel } from "./Notifications";
import { useState } from "react";

type EditFormProps = {
  cancelEdit: () => void;
  onSubmit: (item: CarrierContactQuoteOfferNotificationViewModel) => void;
  item: CarrierContactQuoteOfferNotificationViewModel;
  vehicleTypes: { ID: number, Name: string }[];
}

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const EditNotifictionForm = (props: EditFormProps) => {
    const { cancelEdit, onSubmit, item, vehicleTypes } = props;

    const [vehicleTypeID, setVehicleTypeID] = useState<number>(item.VehicleTypeID || 0);
    const [fromState, setFromState] = useState<string>(item.FromState);
    const [toState, setToState] = useState<string>(item.ToState);
    const [weekdays, setWeekdays] = useState<string>(item.Weekdays);
    const [startTime, setStartTime] = useState<string>(item.StartTime);
    const [endTime, setEndTime] = useState<string>(item.EndTime);
    const [matchedPostedVehicle, setMatchedPostedVehicle] = useState<boolean>(item.MatchedPostedVehicle);

    return <Dialog
        title="Edit Email Notification"
        onClose={cancelEdit}
        width={500}
      >
        <FormElement>
          <FieldWrapper>
            <Label editorId="VehicleTypeID" className="k-form-label">
              Vehicle Type
            </Label>
            <div className="k-form-field-wrap">
              <DropDownList
                id="VehicleTypeID"
                name="VehicleTypeID"
                data={vehicleTypes}
                textField="Name"
                dataItemKey="ID"
                value={props.vehicleTypes.find(x => x.ID === vehicleTypeID) || null}
                onChange={(e) => setVehicleTypeID(e.target.value.ID)}
            />
            </div>
          </FieldWrapper>
          <FieldWrapper>
            <Label editorId="FromState" className="k-form-label">
              From State Code
            </Label>
            <div className="k-form-field-wrap">
              <TextBox
                value={fromState}
                placeholder="Leave blank for any from state"
                onChange={(e) => setFromState(e.value as string)}
                maxLength={2}
              />
            </div>
          </FieldWrapper>
          <FieldWrapper>
            <Label editorId="ToState" className="k-form-label">
              To State Code
            </Label>
            <TextBox
              value={toState}
              placeholder="Leave blank for any to state"
              onChange={(e) => setToState(e.value as string)}
              maxLength={2}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label editorId="Weekdays" className="k-form-label">
              Schedule
            </Label>
            <div className="k-form-field-wrap">
              <MultiSelect
                id="Weekdays"
                name="Weekdays"
                data={daysOfWeek}
                value={daysOfWeek.filter((_, i) => weekdays[i] === 'Y')}
                onChange={(e) => setWeekdays(daysOfWeek.map((x) => e.target.value.includes(x) ? 'Y' : 'N').join(''))}
              />
            </div>
          </FieldWrapper>
          <FieldWrapper>
            <Label editorId="StartTime" className="k-form-label">
              Schedule Start EST
            </Label>
            <MaskedTextBox
              value={startTime}
              mask="00:00:00"
              placeholder="00:00:00"
              onChange={(e) => setStartTime(e.value as string)}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label editorId="EndTime" className="k-form-label">
              Schedule End EST
            </Label>
            <MaskedTextBox
              value={endTime}
              mask="00:00:00"
              placeholder="23:59:59"
              onChange={(e) => setEndTime(e.value as string)}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label editorId="MatchedPostedVehicle" className="k-form-label">
              Only If Matches With Your Posted Vehicles
            </Label>
            <Checkbox
              id="MatchedPostedVehicle"
              name="MatchedPostedVehicle"
              checked={matchedPostedVehicle}
              onChange={(e) => setMatchedPostedVehicle(e.value)}
            />
          </FieldWrapper>
        </FormElement>
      <DialogActionsBar layout="end">
        <Button
          type="submit"
          themeColor="primary"
          onClick={() => onSubmit({
            ...item,
            VehicleTypeID: vehicleTypeID,
            FromState: fromState,
            ToState: toState,
            Weekdays: weekdays,
            StartTime: startTime,
            EndTime: endTime,
            MatchedPostedVehicle: matchedPostedVehicle,
          })}
        >
          {item.CarrierContactQuoteOfferNotificationID ? "Update" : "Add"}
        </Button>
        <Button
          onClick={cancelEdit}
        >
          Cancel
        </Button>
      </DialogActionsBar>
    </Dialog>;
};
export default EditNotifictionForm;