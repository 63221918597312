import './ColumnMenu.css';
import * as React from 'react';
import { GridColumnMenuSort, GridColumnMenuFilter, GridColumnMenuProps } from '@progress/kendo-react-grid';

export default class ColumnMenu extends React.Component<GridColumnMenuProps> {
  render() {
      return (
      <div>
          <GridColumnMenuSort {...this.props} />
          <GridColumnMenuFilter {...this.props} filterOperators={this.props.filterOperators} />
      </div>);
  }
}
