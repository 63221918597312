
import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { CarrierLoadBoardView } from 'TypeGen/carrier-load-board-view';

import { Button } from '@progress/kendo-react-buttons';

export default function MyCommandCell(
  decline: (quoteOffer: CarrierLoadBoardView) => void) {
  return class extends React.Component<GridCellProps> {
    render() {
      const data = this.props.dataItem as CarrierLoadBoardView;
      return <td><Button themeColor="warning" icon="close" title='Decline' onClick={() => decline(data)} /></td>
    }
  }
}
