import { Grid, GridColumn as Column, GridColumnProps, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import CountdownCell from 'components/CountdownCell';
import DateTimeCell from 'components/DateTimeCell';
import { formatNumber } from '@progress/kendo-intl';
import ColumnMenu from './ColumnMenu';
import MyCommandCell from './MyCommandCell';
import LoadBoardDetailRow from './DetailRow';
import { IconButton, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useLoadBoardDispatch, useLoadBoardState } from 'contexts/loadboard/context';

const LoadBoard = () => {

  const { refresh, reset, isColumnActive, changeDataState, hasFilter, toggleFilter, setExpand, decline } = useLoadBoardDispatch();
  const { dataState, gridData } = useLoadBoardState();

  const columnProps = (field: string, headerClassName: string = ''): Partial<GridColumnProps> =>{
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: isColumnActive(field) ? 'active ' + headerClassName : headerClassName
    };
  }

  return <>
    <Grid
      size="small"
      reorderable
      //onColumnReorder={saveColumnOrder}
      dataItemKey="QuoteOfferID"
      sortable={{ allowUnsort: true }}
      pageable={{ pageSizes: [20, 50, 100, 500] }}
      scrollable="none"
      total={gridData.total}
      data={gridData.data}
      onDataStateChange={(e) => changeDataState(e.dataState)}
      detail={LoadBoardDetailRow}
      expandField="expanded"
      onExpandChange={(e) => setExpand(e.dataItem, e.value)}
      {...dataState}
    >
      <GridNoRecords>
        No active offers. Keep this page open to receive notifications.
      </GridNoRecords>
      <GridToolbar>
        <Button
          title="Clear Filters and Sort"
          icon="filter-clear"
          onClick={reset}
        />
        <Button
          title="Refresh"
          icon="refresh"
          onClick={refresh}
        />
        <Button
          themeColor={hasFilter({ field: 'VehicleTypeFilter', operator: 'eq', value: 'CARGO VAN'}) ? 'primary' : undefined}
          onClick={() => toggleFilter({ field: 'VehicleTypeFilter', operator: 'eq', value: 'CARGO VAN'})}
        >CARGO VAN
        </Button>
        <Button
          themeColor={hasFilter({ field: 'VehicleTypeFilter', operator: 'eq', value: 'SPRINTER'}) ? 'primary' : undefined}
          onClick={() => toggleFilter({ field: 'VehicleTypeFilter', operator: 'eq', value: 'SPRINTER'})}
        >SPRINTER
        </Button>
        <Button
          themeColor={hasFilter({ field: 'VehicleTypeFilter', operator: 'eq', value: 'STRAIGHT'}) ? 'primary' : undefined}
          onClick={() => toggleFilter({ field: 'VehicleTypeFilter', operator: 'eq', value: 'STRAIGHT'})}
        >STRAIGHT
        </Button>
        <Button
          themeColor={hasFilter({ field: 'VehicleTypeFilter', operator: 'eq', value: 'TRACTOR'}) ? 'primary' : undefined}
          onClick={() => toggleFilter({ field: 'VehicleTypeFilter', operator: 'eq', value: 'TRACTOR'})}
        >TRACTOR
        </Button>
        <Button
          themeColor={hasFilter({ field: 'VehicleTypeFilter', operator: 'eq', value: 'FLATBED'}) ? 'primary' : undefined}
          onClick={() => toggleFilter({ field: 'VehicleTypeFilter', operator: 'eq', value: 'FLATBED'})}
        >FLATBED
        </Button>
        <Button
          themeColor={hasFilter({ field: 'LocationType', operator: 'eq', value: 1}) ? 'primary' : undefined}
          onClick={() => toggleFilter({ field: 'LocationType', operator: 'eq', value: 1})}
        >US Domestic
        </Button>
        <Button
          themeColor={hasFilter({ field: 'LocationType', operator: 'eq', value: 3}) ? 'primary' : undefined}
          onClick={() => toggleFilter({ field: 'LocationType', operator: 'eq', value: 3})}
        >Mexico
        </Button>
        <Button
          themeColor={hasFilter({ field: 'LocationType', operator: 'eq', value: 2}) ? 'primary' : undefined}
          onClick={() => toggleFilter({ field: 'LocationType', operator: 'eq', value: 2})}
        >Canada
        </Button>
      </GridToolbar>
      <Column {...columnProps("QuoteID")} title="Ref" filter="numeric" />
      <Column {...columnProps("PickupLocation")} title="PU City" />
      <Column {...columnProps("PickupState")} title="PU State" />
      <Column {...columnProps("PickupDateTime")} title="PU Date" filter="date" cell={DateTimeCell} />
      <Column {...columnProps("DeliveryLocation")} title="DEL City" />
      <Column {...columnProps("DeliveryState")} title="DEL State" />
      <Column {...columnProps("DeliveryDateTime")} title="DEL Date" filter="date" cell={DateTimeCell} />
      <Column
        {...columnProps("TotalPieces", 'align-right')}
        title="Pieces"
        filter="numeric"
        cell={(e) => <td style={{ textAlign: 'right' }}>{formatNumber(e.dataItem.TotalPieces, 'n0')}</td>}
      />
      <Column {...columnProps("TotalWeight")} title="Weight" filter="numeric" format="{0:n0} lbs" />
      <Column
        {...columnProps("Distance", 'align-right')}
        title="Miles"
        filter="numeric"
        cell={(e) => <td style={{ textAlign: 'right' }}>{formatNumber(e.dataItem.Distance, 'n0')}</td>}
      />
      <Column
        {...columnProps("VehicleType")}
        title="Equipment"
        width={181}
        cell={(props) =>
          <td>
            {(props.dataItem.CarrierVehicleNumber || props.dataItem.VehicleSearchUrl) ? <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <span>{props.dataItem.VehicleType}&nbsp;</span>
              {props.dataItem.CarrierVehicleNumber ? <Tooltip title={`Unit #${props.dataItem.CarrierVehicleNumber} Matches!`}>
                  {props.dataItem.VehicleSearchUrl ? <IconButton onClick={() => window.open(props.dataItem.VehicleSearchUrl)}>
                    <LocalShippingIcon color="warning" />
                  </IconButton> : <LocalShippingIcon color="warning" />}
                </Tooltip>
              : <IconButton onClick={() => window.open(props.dataItem.VehicleSearchUrl)}>
                <SearchIcon color="warning" />
              </IconButton>}
            </div> : props.dataItem.VehicleType}
          </td>}
      />
      <Column field="ExpiryDateTime" title="Expiry" filterable={false} filter="date" width={124} cell={CountdownCell} />
      <Column cell={MyCommandCell(decline)} />
    </Grid>
  </>
}

export default LoadBoard;