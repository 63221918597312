import { Suspense } from 'react';
import { GridDetailRow, GridDetailRowProps } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import LoadBoardBid from './Bid';
import { CarrierLoadBoardView } from 'TypeGen/carrier-load-board-view';
import lazyWithRetry from 'utils/lazyWithRetry';

const LoadBoardMap = lazyWithRetry(() => import('./Map'));

export default class LoadBoardDetailRow extends GridDetailRow {
  private selectedIndex: number;
  private renderMap: boolean;

  constructor(props: GridDetailRowProps) {
    super(props);

    this.selectedIndex = 0;
    this.renderMap = false;

    this.handleSelect = this.handleSelect.bind(this);
  }

  render() {
      const dataItem = this.props.dataItem as CarrierLoadBoardView;
      const props = { quoteId: dataItem.QuoteID, quoteOfferId: dataItem.QuoteOfferID };
      return (
        <TabStrip selected={this.selectedIndex} onSelect={this.handleSelect} animation={false} keepTabsMounted>
          <TabStripTab title="Bid">
            <LoadBoardBid {...props} />
          </TabStripTab>
          <TabStripTab title="Map">
            <Suspense fallback={<>Loading...</>}>
              {this.renderMap && <LoadBoardMap {...props} />}
            </Suspense>
          </TabStripTab>
        </TabStrip>
      );
  }

  private handleSelect(e: TabStripSelectEventArguments) {
    this.selectedIndex = e.selected;
    this.renderMap = this.selectedIndex === 1 || this.renderMap;
    this.forceUpdate();
  }
}
