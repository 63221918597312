export const dimsUnitOfMeasureAbbreviation = (dimsUnitOfMeasure: number): string => {
  switch (dimsUnitOfMeasure) {
      case 0: return 'in';
      case 1: return 'cm';
      case 2: return 'mm';
      default: throw new Error("Invalid Dims Type!");
  }
}

export const weightUnitOfMeasureAbbreviation = (weightUnitOfMeasure: number): string => {
  switch (weightUnitOfMeasure) {
      case 0: return 'lbs';
      case 1: return 'kgs';
      default: throw new Error("Invalid Weight Type!");
  }
}

export const dimsDisplayValue = (inches: number, dimsUnitOfMeasure: number): number => {
  const dimRound = (value: number) => Math.round((value + Number.EPSILON) * 10) / 10;
  switch (dimsUnitOfMeasure) {
      case 0: return dimRound(inches);
      case 1: return dimRound(inches * 2.54);
      case 2: return dimRound(inches * 25.4);
      default: throw new Error("Invalid Dims Type!");
  }
}

export const weightDisplayValue = (pounds: number, weightUnitOfMeasure: number): number => {
  switch (weightUnitOfMeasure) {
      case 0: return Math.round(pounds);
      case 1: return Math.round(pounds * 0.453592);
      default: throw new Error("Invalid Weight Type!");
  }
}
