import { Alert, Button, Checkbox, Chip, FormControlLabel, FormGroup, Grid, Link, Paper, Stack, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { fetchApi } from 'core/fetchApi';
import { formatNumber } from '@progress/kendo-intl';
import { Input, NumericTextBox, NumericTextBoxHandle, TextArea } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { useEffect, useRef, useState } from 'react';
import { dimsDisplayValue, dimsUnitOfMeasureAbbreviation, weightDisplayValue, weightUnitOfMeasureAbbreviation } from 'utils/display';
import { formatDateTime } from 'utils/format';
import useConfirm from 'utils/useConfirm';
import { ILink } from 'core/link';
import { parseJSON } from 'date-fns';
import { format } from 'date-fns-tz';

type Props = {
  quoteId: number;
  quoteOfferId: number;
}

type Quote = {
  OfferedBy: string;
  OfferedByEmail: string;
  OfferDateTime: Date;
  VehicleTypeName: string;
  OfferNote: string;

  HazMat: boolean;
  DockHigh: boolean;
  LiftGate: boolean;
  BookItRate: number;
  Confirmed: boolean;
  RequireVehicleDimensions: boolean;

  // Response
  Note: string;
  AlternativePickup: Date | null;
  AlternativeDelivery: Date | null;
  MilesFromPickup: number | null;
  BoxLength: number | null;
  BoxWidth: number | null;
  BoxHeight: number | null;
  IsVehicleEmpty: boolean | null;
  DispatcherName: string;

  Stops: QuoteStop[];
  Freight: QuoteFreight[];
  Links: ILink[];
}

type QuoteStop = {
  QuoteID: number;
  StopType: 0|1;
  StopVerb: string;
  City: string;
  State: string;
  ZipCode: string;
  ScheduledDateTime: Date;
  Distance: number;
}

type QuoteFreight = {
  QuoteFreightID: number;
  Pieces: number;
  Weight: number;
  WeightUnitOfMeasure: 0|1;
  Length: number;
  Width: number;
  Height: number;
  DimsUnitOfMeasure: 0|1|2;
  Stackable: boolean;
}

type QuoteResponse = {
  AllInRate: number;
  Note: string;
  AlternativePickup: Date | null;
  AlternativeDelivery: Date | null;
  MilesFromPickup: number | null;
  BoxLength: number | null;
  BoxWidth: number | null;
  BoxHeight: number | null;
  IsVehicleEmpty: boolean | null;
  DispatcherName: string;
}

const LoadBoardBid = (props: Props) => {
  const { ConfirmationDialog, confirm } = useConfirm({
    labels: {
      confirm: 'Book It Now',
      cancel: 'Cancel',
    },
    themeColor: 'success',
  });
  const [loading, setLoading] = useState(true);
  const [quote, setQuote] = useState<Quote | null>(null);

  const [allInRateError, setAllInRateError] = useState('');
  const [showAlternativePickup, setShowAlternativePickup] = useState(true);
  const [alternativePickupError, setAlternativePickupError] = useState('');
  const [showAlternativeDelivery, setShowAlternativeDelivery] = useState(true);
  const [alternativeDeliveryError, setAlternativeDeliveryError] = useState('');
  const [milesFromPickupError, setMilesFromPickupError] = useState('');
  const [vehicleDimsError, setVehicleDimsError] = useState('');
  const [isVehicleEmptyError, setIsVehicleEmptyError] = useState('');
  const [success, setSuccess] = useState(false);
  const [successRetraction, setSuccessRetraction] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [quoteResponse, setQuoteResponse] = useState<QuoteResponse>({
    AllInRate: 0,
    Note: '',
    AlternativePickup: null,
    AlternativeDelivery: null,
    MilesFromPickup: null,
    BoxLength: null,
    BoxWidth: null,
    BoxHeight: null,
    IsVehicleEmpty: null,
    DispatcherName: '',
  });

  const allInRateRef = useRef<NumericTextBoxHandle>(null);

  useEffect(() => {
    fetchApi<Quote>({ url: `LoadBoardDetails/${props.quoteId}/${props.quoteOfferId}` })
      .then((response) => {
        setLoading(false);
        setQuote(response);
        setQuoteResponse({
          AllInRate: response.BookItRate,
          Note: response.Note,
          AlternativePickup: response.AlternativePickup ? parseJSON(response.AlternativePickup) : null,
          AlternativeDelivery: response.AlternativeDelivery ? parseJSON(response.AlternativeDelivery) : null,
          MilesFromPickup: response.MilesFromPickup,
          BoxLength: response.BoxLength,
          BoxWidth: response.BoxWidth,
          BoxHeight: response.BoxHeight,
          IsVehicleEmpty: response.IsVehicleEmpty,
          DispatcherName: response.DispatcherName || localStorage.getItem('Bid_DispatcherName') || '',
        });
        const canBid = response.Links.some((link) => link.Name === 'Bid');
        if (canBid) {
          setShowAlternativePickup(response.AlternativePickup !== null || response.Confirmed === false);
          setShowAlternativeDelivery(response.AlternativeDelivery !== null || response.Confirmed === false);
        } else {
          setShowAlternativePickup(response.AlternativePickup !== null);
          setShowAlternativeDelivery(response.AlternativeDelivery !== null);
        }
      });
  }, [props.quoteId, props.quoteOfferId]);

  useEffect(() => {
    if (!loading) allInRateRef.current?.focus();
  }, [loading]);

  const updateQuoteResponse = (value: Partial<QuoteResponse>) => {
    const quoteResponseValue = Object.assign({}, quoteResponse, value);
    setQuoteResponse(quoteResponseValue);
  }

  const submitBid = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const allInRateError = (quoteResponse.AllInRate === 0) ? 'Please enter an all-in rate.' : '';
    const alternativePickupError = (showAlternativePickup && !quoteResponse.AlternativePickup) ? 'Please enter alternative pickup date.' : '';
    const alternativeDeliveryError = (showAlternativeDelivery && !quoteResponse.AlternativeDelivery) ? 'Please enter alternative delivery date.' : '';
    const milesFromPickupError = (quoteResponse.MilesFromPickup === null) ? 'Please enter miles from pickup.' : '';
    const vehicleDimsError = (quote?.RequireVehicleDimensions === true && (quoteResponse.BoxLength === null || quoteResponse.BoxWidth === null || quoteResponse.BoxHeight === null)) ? 'Please enter vehicle dimensions.' : '';
    const isVehicleEmptyError = (quoteResponse.IsVehicleEmpty === null) ? 'Please indicate if vehicle is empty.' : '';

    if (allInRateError || alternativePickupError || alternativeDeliveryError || milesFromPickupError || vehicleDimsError || isVehicleEmptyError) {
      setAllInRateError(allInRateError);
      setAlternativePickupError(alternativePickupError);
      setAlternativeDeliveryError(alternativeDeliveryError);
      setMilesFromPickupError(milesFromPickupError);
      setVehicleDimsError(vehicleDimsError);
      setIsVehicleEmptyError(isVehicleEmptyError);
      return;
    }

    if (quote?.Confirmed && !await confirm("Confirm Book It Now?")) {
      return;
    }

    setLoading(true);

    const data = {
      QuoteID: props.quoteId,
      QuoteOfferID: props.quoteOfferId,
      AllInRate: quoteResponse.AllInRate,
      Note: quoteResponse.Note,
      AlternativePickup: showAlternativePickup ? quoteResponse.AlternativePickup : null,
      AlternativeDelivery: showAlternativeDelivery ? quoteResponse.AlternativeDelivery : null,
      MilesFromPickup: quoteResponse.MilesFromPickup,
      BoxLength: quoteResponse.BoxLength,
      BoxWidth: quoteResponse.BoxWidth,
      BoxHeight: quoteResponse.BoxHeight,
      IsVehicleEmpty: quoteResponse.IsVehicleEmpty,
      DispatcherName: quoteResponse.DispatcherName,
    }

    fetchApi<{ Success: boolean, ErrorMessage: string }>({ url: 'LoadBoardBid', method: "POST", payload: data })
      .then((response) => {
        setLoading(false);
        if (response.Success) {
          setSuccess(true);
        } else {
          setErrorMessage(response.ErrorMessage);
        }
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage('Our apologies, but the server has failed to process your request.  Please try again.');
      });
  }

  const retractBid = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      QuoteID: props.quoteId,
      QuoteOfferID: props.quoteOfferId,
    }

    fetchApi<{ Success: boolean, ErrorMessage: string }>({ url: 'LoadBoardRetractBid', method: "POST", payload: data })
      .then((response) => {
        setLoading(false);
        if (response.Success) {
          setSuccessRetraction(true);
        } else {
          setErrorMessage(response.ErrorMessage);
        }
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage('Our apologies, but the server has failed to process your request.  Please try again.');
      });
  }

  if (loading || quote === null) {
    return (
      <span
        className="k-i-loading k-icon"
        style={{ fontSize: 64, position: 'absolute', left: '50%', top: '50px' }}
      />
    );
  }

  if (success) {
    return (
      <div className="k-my-2">
        <Typography variant="h6">Bid Submitted</Typography>
        <Typography variant="subtitle1">Your bid has been submitted successfully.</Typography>
      </div>
    );
  }

  if (successRetraction) {
    return (
      <div className="k-my-2">
        <Typography variant="h6">Retracted</Typography>
        <Typography variant="subtitle1">Your response has been retracted successfully.</Typography>
      </div>
    );
  }

  const cannotBid = !quote.Links.find(x => x.Name === 'Bid');

  return <>
    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    <ConfirmationDialog />
    <Grid container spacing={2}>
      <Grid item xs={8}>
        {quote.OfferNote && <Alert severity="info" variant="filled">{quote.OfferNote}</Alert>}
        <Stack direction="row" spacing={1} className="k-my-2">
          {quote.HazMat && <Chip label="Hazardous" color="error" />}
          {quote.DockHigh && <Chip label="Dock High" color="primary" />}
          {quote.LiftGate && <Chip label="Lift Gate" color="primary" />}
        </Stack>
        <TableContainer component={Paper} className="k-my-2">
          <Table sx={{ minWidth: 650}}>
            <TableHead>
              <TableRow>
                <TableCell>Stop</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Scheduled</TableCell>
                <TableCell align="right">Miles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quote.Stops.map((stop, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {stop.StopType === 0 ? 'Pickup' : 'Delivery'}
                    </TableCell>
                    <TableCell>
                      {stop.City}, {stop.State} {stop.ZipCode}
                    </TableCell>
                    <TableCell>
                      {formatDateTime(stop.ScheduledDateTime)} {stop.StopVerb}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right', padding: 16 }}>
                      {formatNumber(stop.Distance, 'n0')}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} className="k-my-2">
          <Table sx={{ minWidth: 650}}>
            <TableHead>
              <TableRow>
                <TableCell align='right'>Pieces</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Dims (LxWxH)</TableCell>
                <TableCell>Stackable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quote.Freight.map((freight, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align='right'>
                      {formatNumber(freight.Pieces, 'n0')}
                    </TableCell>
                    <TableCell>
                      {formatNumber(weightDisplayValue(freight.Weight, freight.WeightUnitOfMeasure), 'n0')} {weightUnitOfMeasureAbbreviation(freight.WeightUnitOfMeasure)}
                    </TableCell>
                    <TableCell>
                      {dimsDisplayValue(freight.Length, freight.DimsUnitOfMeasure)} x {dimsDisplayValue(freight.Width, freight.DimsUnitOfMeasure)} x {dimsDisplayValue(freight.Height, freight.DimsUnitOfMeasure)} {dimsUnitOfMeasureAbbreviation(freight.DimsUnitOfMeasure)}
                    </TableCell>
                    <TableCell>
                      {freight.Stackable ? 'Yes' : 'No'}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="caption">Offered by <Link title="Email" style={{ textDecoration: 'underline' }} href={`mailto:${quote.OfferedByEmail}?subject=${props.quoteId}`}>{quote.OfferedBy}</Link> at {formatDateTime(quote.OfferDateTime)}.</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6">Bid Response</Typography>
        <NumericTextBox
          ref={allInRateRef}
          format="c0"
          placeholder="Your All-In Rate"
          onChange={e => {
            updateQuoteResponse({ AllInRate: e.value || 0 });
            if (e.value) setAllInRateError('');
          }}
          defaultValue={quote.BookItRate || undefined}
          disabled={quote.Confirmed || cannotBid}
          spinners={false}
          step={0}
          min={0}
        />
        {allInRateError && <Error>{allInRateError}</Error>}
        <FormGroup className={showAlternativePickup ? 'k-mb-2' : ''}>
          <FormControlLabel
            control={<Checkbox disabled={quote.Confirmed || cannotBid} checked={!showAlternativePickup} onChange={(e) => setShowAlternativePickup(!e.target.checked)} />}
            label="I can make the pickup time"
          />
          {showAlternativePickup && <>
            <DateTimePicker
              ampm={false}
              disabled={cannotBid}
              format="MM/dd/yyyy HH:mm"
              label={`Alternative Pickup Time (${format(new Date(), "zzz")})`}
              value={quoteResponse.AlternativePickup}
              onChange={(e) => {
                updateQuoteResponse({ AlternativePickup: e });
                if (e) setAlternativePickupError('');
              }}
              slotProps={{
                actionBar: { actions: ["clear", "today"] },
              }}
            />
            {alternativePickupError && <Error>{alternativePickupError}</Error>}
          </>}
        </FormGroup>
        <FormGroup className={showAlternativeDelivery ? 'k-mb-2' : ''}>
          <FormControlLabel
            control={<Checkbox disabled={quote.Confirmed || cannotBid} checked={!showAlternativeDelivery} onChange={(e) => setShowAlternativeDelivery(!e.target.checked)} />}
            label="I can make the delivery time"
          />
          {showAlternativeDelivery && <>
            <DateTimePicker
              ampm={false}
              disabled={cannotBid}
              format="MM/dd/yyyy HH:mm"
              label={`Alternative Delivery Time (${format(new Date(), "zzz")})`}
              value={quoteResponse.AlternativeDelivery}
              onChange={(e) => {
                updateQuoteResponse({ AlternativeDelivery: e });
                if (e) setAlternativeDeliveryError('');
              }}
              slotProps={{
                actionBar: { actions: ["clear", "today"] },
              }}
            />
            {alternativeDeliveryError && <Error>{alternativeDeliveryError}</Error>}
          </>}
        </FormGroup>
        <FormGroup className='k-mb-2'>
          <NumericTextBox
            format="##,# 'miles out'"
            disabled={cannotBid}
            placeholder="Miles to Pickup"
            onChange={e => {
              updateQuoteResponse({ MilesFromPickup: e.value || null });
              if (e.value) setMilesFromPickupError('');
            }}
            defaultValue={quote.MilesFromPickup || undefined}
            spinners={false}
            step={0}
            min={0}
          />
          {milesFromPickupError && <Error>{milesFromPickupError}</Error>}
        </FormGroup>
        <FormGroup className='k-justify-content-between' row>
          <NumericTextBox
            width="33%"
            format="##,# 'in'"
            disabled={cannotBid}
            placeholder="Box Length"
            onChange={e => {
              updateQuoteResponse({ BoxLength: e.value || null });
              setVehicleDimsError('');
            }}
            defaultValue={quote.BoxLength || undefined}
            spinners={false}
            step={0}
            min={0}
          />
          <NumericTextBox
            width="33%"
            format="##,# 'in'"
            disabled={cannotBid}
            placeholder="Box Width"
            onChange={e => {
              updateQuoteResponse({ BoxWidth: e.value || null });
              setVehicleDimsError('');
            }}
            defaultValue={quote.BoxWidth || undefined}
            spinners={false}
            step={0}
            min={0}
          />
          <NumericTextBox
            width="33%"
            format="##,# 'in'"
            disabled={cannotBid}
            placeholder="Box Height"
            onChange={e => {
              updateQuoteResponse({ BoxHeight: e.value || null });
              setVehicleDimsError('');
            }}
            defaultValue={quote.BoxHeight || undefined}
            spinners={false}
            step={0}
            min={0}
          />
        </FormGroup>
        {vehicleDimsError && <Error className='k-mb-2'>{vehicleDimsError}</Error>}
        <FormGroup>
          <FormControlLabel
            control={<Checkbox
              disabled={cannotBid}
              checked={quoteResponse.IsVehicleEmpty ?? false}
              indeterminate={quoteResponse.IsVehicleEmpty === null}
              onChange={(e) => {
                updateQuoteResponse({ IsVehicleEmpty: e.target.checked });
                setIsVehicleEmptyError('');
              }}
            />}
            label="Vehicle is empty now"
          />
          {isVehicleEmptyError && <Error className='k-mb-2'>{isVehicleEmptyError}</Error>}
        </FormGroup>
        <TextArea placeholder="Notes" value={quoteResponse.Note} onChange={e => updateQuoteResponse({ Note: e.value || '' })} disabled={cannotBid} />
        <FormGroup className='k-mt-2'>
          <Input
            placeholder="Your Name"
            maxLength={60}
            value={quoteResponse.DispatcherName}
            onChange={e => {
              updateQuoteResponse({ DispatcherName: e.value });
              localStorage.setItem('Bid_DispatcherName', e.value);
            }}
            disabled={cannotBid}
          />
        </FormGroup>
        <Stack direction="row" spacing={1} className="k-my-2">
          <Button variant="contained" onClick={submitBid} disabled={loading || cannotBid}>{quote.Confirmed ? "Book It Now" : "Submit Bid"}</Button>
          {quote.Links.find(x => x.Name === "RetractBid") && <Button variant="contained" color="error" onClick={retractBid} disabled={loading}>Retract</Button>}
        </Stack>
      </Grid>
    </Grid>
  </>
}

export default LoadBoardBid