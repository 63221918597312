import { lazy, LazyExoticComponent } from 'react';

const lazyWithRetry = <T extends React.ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>
): LazyExoticComponent<T> =>
  lazy(async () => {
    try {
      return await componentImport();
    } catch (error) {
      if (error instanceof Error && error.name === 'ChunkLoadError') {
        window.location.reload();
      }
      throw error;
    }
  });

export default lazyWithRetry;